import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/loader";
// import SensAB from "./pages/sensab/page";
const HomePage = lazy(() => import("./pages/home"));
const AboutUS = lazy(() => import("./pages/sensab/subpages/AboutUs"));
const SesnABAboutUS = lazy(() => import("./pages/sensab/subpages/AboutUs"));
const SensAB = lazy(() => import("./pages/sensab/page"));
function App() {
  return (
    <Suspense fallback={<Loader height={"100vh"} />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/s/sensab" element={<SensAB />} />
        <Route path="/sensab" element={<SensAB />} />
        <Route path="/sensab/about" element={<SesnABAboutUS />} />
      </Routes>
    </Suspense>
  );
}

export default App;
